import React from "react";
import classNames from "classnames";

interface ModalHeaderProps {
  text: string;
  className?: string;
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({
  text,
  className,
}) => {
  return (
    <h2
      className={classNames(
        "font-semibold text-gray-900 flex items-center",
        className,
        "text-lg"
      )}
    >
      {text}
    </h2>
  );
};
