import { useState, useEffect, useRef } from "react";
import { nameMapEur, nameMapSek } from "../securities";
interface QuoteData {
  buy: string;
  sell: string;
}

export interface QuotesData {
  [key: string]: QuoteData;
}

const useFetchQuotes = (currency: "EUR" | "SEK"): QuotesData => {
  const url = process.env.REACT_APP_QUOTES_API_URL || "";
  const [quotesData, setQuotesData] = useState<QuotesData>({});
  const intervalIdRef = useRef<number | NodeJS.Timeout>();

  useEffect(() => {
    let isCancelled = false;

    const fetchData = async () => {
      try {
        // Choose the appropriate name map based on the currency
        const nameMap = currency === "SEK" ? nameMapSek : nameMapEur;

        const response = await fetch(url as RequestInfo);
        const quotes = await response.json();
        const newRenamedPairs: QuotesData = {};
        for (const key in quotes) {
          if (nameMap[key]) {
            nameMap[key].forEach((alias) => {
              newRenamedPairs[alias] = quotes[key];
            });
          }
        }
        if (!isCancelled) {
          setQuotesData(newRenamedPairs);
        }
      } catch (error) {
        if (!isCancelled) {
          console.error(error);
        }
      }
    };

    fetchData();
    intervalIdRef.current = setInterval(fetchData, 3000);

    return () => {
      isCancelled = true;
      clearInterval(intervalIdRef.current as unknown as number);
    };
  }, [url, currency]);

  return quotesData;
};

export default useFetchQuotes;
