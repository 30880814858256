import { useEffect } from "react";
import { isDevelopment, isMobileApp } from "config";

const initMetaPixel = () => {
  if (window.fbq) return;

  window.fbq = function () {
    window.fbq.callMethod
      ? window.fbq.callMethod.apply(window.fbq, arguments)
      : window.fbq.queue.push(arguments);
  };

  if (!window._fbq) window._fbq = window.fbq;
  window.fbq.push = window.fbq;
  window.fbq.loaded = true;
  window.fbq.version = "2.0";
  window.fbq.queue = [];
  let script = document.createElement("script");
  script.async = true;
  script.src = "https://connect.facebook.net/en_US/fbevents.js";
  document.body.appendChild(script);

  window.fbq("init", "1207652919887925");
  window.fbq("track", "PageView");
};

// Function to initialize Twitter Conversion Tracking
const initTwitterTracking = () => {
  if (window.twq) return;

  window.twq = function () {
    window.twq.exe
      ? window.twq.exe.apply(window.twq, arguments)
      : window.twq.queue.push(arguments);
  };

  window.twq.version = "1.1";
  window.twq.queue = [];
  let script = document.createElement("script");
  script.async = true;
  script.src = "https://static.ads-twitter.com/uwt.js";
  document.body.appendChild(script);

  window.twq("config", "ogez2");
};

const initGoogleAnalytics = () => {
  if (window.gtag) return;

  window.dataLayer = window.dataLayer || [];
  window.gtag = function () {
    window.dataLayer.push(arguments);
  };
  window.gtag("js", new Date());

  const script = document.createElement("script");
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=G-FNR21DE7ZT`;
  document.head.appendChild(script);

  script.onload = () => {
    window.gtag("config", "G-FNR21DE7ZT");
  };
};

const initKivaAnalytics = () => {
  if (document.querySelector('script[src*="kiva.tools.min.js"]')) return;

  const script = document.createElement("script");
  script.async = true;
  script.src = "https://analytics.kiva.fi/kiva.tools.min.js";
  document.head.appendChild(script);

  // Initialize kivaLayer
  window.kivaLayer = window.kivaLayer || [];

  script.onload = () => {
    window.kivaLayer.push("config", "G-7SMC1LW3BM");
    window.kivaLayer.push("pageview");
  };
};

const loadCookieConsent = () => {
  console.log("loadCookieConsent");
  const script = document.createElement("script");
  script.id = "CookieConsent";
  script.src = "https://policy.app.cookieinformation.com/uc.js";
  script.setAttribute("data-culture", "FI");
  script.setAttribute("data-gcm-version", "2.0");
  script.type = "text/javascript";

  document.body.appendChild(script);
};

export const useTrackingScripts = () => {
  useEffect(() => {
    if (isDevelopment || isMobileApp) return;
    loadCookieConsent();

    initMetaPixel();
    initTwitterTracking();
    initGoogleAnalytics();
    initKivaAnalytics();
  }, []);
};
