import React from "react";

interface DividerProps {
  className?: string;
  color?: string;
  thickness?: string;
}

export const Divider: React.FC<DividerProps> = ({
  className,
  color = "border-gray-200",
  thickness = "border-t",
}) => {
  return (
    <div
      className={`w-full ${thickness} ${color} ${className || "my-4"}`}
      style={{
        backgroundColor: color.includes("border-") ? undefined : color,
      }}
    />
  );
};
