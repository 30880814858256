import { Children, HTMLAttributes, isValidElement, ReactNode } from "react";
import classNames from "classnames";

interface GridProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  firstChildClassName?: string;
  lastChildClassName?: string;
  middleChildClassName?: string;
}

export const Grid = ({
  children,
  className,
  firstChildClassName = "",
  lastChildClassName = "",
  middleChildClassName = "",
  ...rest
}: GridProps) => {
  const ChildrenArray = Children.toArray(children);
  return (
    <>
      {ChildrenArray.map((child, index) => {
        return isValidElement(child)
          ? {
              ...child,
              props: {
                ...child.props,
                className: classNames(
                  child?.props?.className ?? "", // child
                  className ?? "", // parent
                  index === 0 ? firstChildClassName : "", // first child
                  index === ChildrenArray.length - 1 ? lastChildClassName : "", // last child
                  index !== 0 && index !== ChildrenArray.length - 1 // middle child
                    ? middleChildClassName
                    : ""
                ),
                ...rest,
              },
            }
          : child;
      })}
    </>
  );
};

interface GridHeaderProps {
  children: ReactNode;
}

const GridHeader = ({ children }: GridHeaderProps) => (
  <Grid
    className="py-2 text-sm font-semibold text-kvarn-dark-green border-b border-kvarn-dark-green"
    firstChildClassName="pl-2 lg:text-left"
    middleChildClassName="lg:text-left pl-2"
    lastChildClassName="lg:text-left pr-2"
  >
    {children}
  </Grid>
);
Grid.Header = GridHeader;

const GridRow = ({ children, onClick, className, ...rest }: GridProps) => (
  <Grid
    firstChildClassName="lg:flex items-center ml-2 lg:text-left"
    middleChildClassName="lg:flex items-center pl-2 lg:text-left"
    lastChildClassName="lg:flex items-center mr-2 lg:text-left"
    onClick={onClick}
    className={
      (className ? className : "") + (onClick ? " cursor-pointer" : "")
    }
    {...rest}
  >
    {children}
  </Grid>
);
Grid.Row = GridRow;
