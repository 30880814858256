import { ReactComponent as DepositIcon } from "assets/deposit.svg";
import { UserMenu, Logo, PortfolioSelect } from "components";
import { useModal } from "components/Modal/useModal";
import { FinsharkDepositModalContent } from "components/MoneyModals/DepositModalContent/FinsharkDepositModalContent";
import {
  TOTAL_INVESTMENTS_OPTION_ID,
  useGetPortfolioOptions,
} from "hooks/useGetPortfolioOptions";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { Navigate, useParams } from "react-router-dom";
import { useNavigateToPortfolioTab } from "./useNavigateToPortfolioTab";
import { useRedirectIfOnlyOnePortfolio } from "./useRedirectIfOnlyOnePortfolio";

export interface PortfolioOption {
  id: number;
  urlPrefix: string;
  label: string;
}

export const PortfolioNavigationHeader = () => {
  const { t } = useModifiedTranslation();
  const portfolioOptions = useGetPortfolioOptions();
  const { portfolioId } = useParams();
  const navigateToPortfolioTab = useNavigateToPortfolioTab();
  useRedirectIfOnlyOnePortfolio();

  const {
    Modal: FinsharkModal,
    onOpen: onFinsharkModalOpen,
    modalProps: finsharkModalProps,
    contentProps: finsharkModalContentProps,
  } = useModal();

  const onPortfolioChange = (selectedOption: PortfolioOption) => {
    navigateToPortfolioTab(selectedOption.urlPrefix);
  };

  const currentPortfolio = portfolioId
    ? parseInt(portfolioId, 10)
    : TOTAL_INVESTMENTS_OPTION_ID;

  // redirect to root when portfolioId does not match available portfolios
  if (
    currentPortfolio !== TOTAL_INVESTMENTS_OPTION_ID &&
    !portfolioOptions.some((option) => option.id === currentPortfolio)
  ) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <div className="z-20 py-4 px-2 bg-center bg-no-repeat bg-cover shadow-md bg-[url('/public/ylapalkki_tausta.png')]">
        <div className="container flex flex-row gap-2 justify-between items-center mx-auto">
          <Logo />

          <button
            className="hidden sm:flex gap-2 items-center py-2 px-4 font-bold text-kvarn-dark-green bg-kvarn-light-green rounded-full transition hover:bg-kvarn-light-green/80"
            onClick={onFinsharkModalOpen}
          >
            <DepositIcon className="text-lg" />
            {t("navTab.tabs.deposit")}
          </button>

          <div className="flex gap-2 sm:gap-4 items-center">
            <div className="flex-shrink-0 sm:min-w-[350px]">
              {portfolioOptions.length > 0 ? (
                <div className="overflow-hidden overflow-ellipsis rounded-full border border-gray-400 max-w-[120px] sm:max-w-[150px] md:max-w-[350px]">
                  <PortfolioSelect
                    portfolioOptions={portfolioOptions}
                    portfolioId={currentPortfolio}
                    onChange={onPortfolioChange}
                    color="dark"
                  />
                </div>
              ) : (
                <div />
              )}
            </div>
            <div className="flex gap-x-2 items-center">
              <UserMenu />
            </div>
          </div>
        </div>
      </div>

      <FinsharkModal
        {...finsharkModalProps}
        header={t("moneyModal.depositModalHeaderRealTime")}
      >
        <FinsharkDepositModalContent {...finsharkModalContentProps} />
      </FinsharkModal>
    </>
  );
};
