import React from "react";
import classNames from "classnames";

interface ToggleButtonProps {
  children: React.ReactNode;
  iconSrc: string;
  onClick?: () => void;
  selected?: boolean;
}

const ToggleButton = ({
  children,
  iconSrc,
  onClick,
  selected,
}: ToggleButtonProps) => {
  return (
    <button
      className={classNames(
        "flex gap-3 items-center py-3 px-3.5 rounded-lg border border-gray-300",
        "focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-400",
        {
          "border-primary-400 bg-primary-50": selected,
          "hover:border-gray-400": !selected,
        }
      )}
      onClick={onClick}
    >
      <img src={iconSrc} alt="icon" />
      {children}
    </button>
  );
};

export default ToggleButton;
