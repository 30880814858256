import { MutableRefObject, useState } from "react";
import { useGetContactInfo } from "api/initial/useGetContactInfo";
import { useWithdrawal } from "api/money/useWithdrawal";
import { useGetBuyDataWithOpenOrders } from "api/trading/useGetBuyDataWithOpenOrderStatus";
import { Input, Button } from "components";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { useGetContactIdData } from "providers/ContactIdProvider";
import { useWithdrawablePortfolioSelect } from "./useWithdrawablePortfolioSelect";
import { CashAccountSelect } from "../components/CashAccountSelect";
import { usePortfoliosAccountsState } from "../usePortfoliosAccountsState";

interface WithdrawModalProps {
  modalInitialFocusRef: MutableRefObject<null>;
  onClose: () => void;
}

export const WithdrawModalContent = ({
  onClose,
  modalInitialFocusRef,
}: WithdrawModalProps) => {
  const { t } = useModifiedTranslation();
  const { selectedContactId } = useGetContactIdData();
  const { data: { portfolios } = { portfolios: [] } } = useGetContactInfo(
    false,
    selectedContactId
  );
  const portfolioSelectProps = useWithdrawablePortfolioSelect();
  const { portfolioId } = portfolioSelectProps;

  const { accountsLoading, ...cashAccountSelectProps } =
    usePortfoliosAccountsState(portfolioId);
  const {
    currentCashAccount: {
      availableBalance = 0,
      currency = "EUR",
      label = "",
      number = "",
    } = {},
  } = cashAccountSelectProps;

  const [amount, setAmount] = useState(0);

  // get the available cash adjusted for open orders
  const { data: { availableCash = 0 } = {}, refetch } =
    useGetBuyDataWithOpenOrders(portfolioId.toString());

  const MIN_WITHDRAWAL_AMOUNT = 10;

  const isAmountCorrect =
    !isNaN(availableBalance) &&
    amount >= MIN_WITHDRAWAL_AMOUNT &&
    amount <= availableCash;

  const getAmountError = () => {
    if (accountsLoading) return undefined;
    if (amount < MIN_WITHDRAWAL_AMOUNT && amount !== 0) {
      return t("moneyModal.minimumWithdrawalError", {
        amount: MIN_WITHDRAWAL_AMOUNT,
        currency,
      });
    }
    if (!isAmountCorrect) {
      return t("moneyModal.amountInputError");
    }
    return undefined;
  };

  const { handleTrade: handleWithdraw, submitting } = useWithdrawal({
    portfolio:
      portfolios.find((portfolio) => portfolio.id === portfolioId) ||
      portfolios[0],
    tradeAmount: amount,
    securityName: label,
    account: number,
    currency,
  });

  return (
    <div className="grid gap-2 min-w-[min(84vw,_375px)]">
      <CashAccountSelect
        {...cashAccountSelectProps}
        {...portfolioSelectProps}
        accountSelectLabel={t("moneyModal.fromAccount")}
        availableCash={availableCash}
      />
      <hr className="mb-2" />
      <div className="flex flex-col gap-4 items-stretch ">
        <Input
          ref={modalInitialFocusRef}
          value={amount || ""}
          onChange={(event) => {
            setAmount(Number(event.currentTarget.value));
          }}
          label={t("moneyModal.withdrawalAmountInputLabel", {
            currency,
          })}
          type="number"
        />
        {getAmountError() && (
          <p className="text-sm text-red-500">{getAmountError()}</p>
        )}
        <Button
          disabled={amount === 0 || accountsLoading || !isAmountCorrect}
          isLoading={submitting}
          onClick={async () => {
            const response = await handleWithdraw();
            if (response) {
              // Refetch the availableCash after a successful withdrawal
              refetch();
              onClose();
            }
          }}
        >
          {t("moneyModal.withdrawButtonLabel")}
        </Button>
      </div>
      <hr className="my-1" />
      <div className="text-xs text-center text-gray-600 max-w-[375px]">
        {t("moneyModal.withdrawDisclaimer")}
      </div>
    </div>
  );
};
