import { Fragment, MutableRefObject, ReactNode } from "react";
import { Dialog, Transition } from "@headlessui/react";

export interface ModalV2Props {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  modalInitialFocusRef: MutableRefObject<null>;
  header: JSX.Element | string;
}

export const ModalV2 = ({
  isOpen,
  onClose,
  children,
  modalInitialFocusRef,
  header,
}: ModalV2Props) => {
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        onClose={onClose}
        initialFocus={modalInitialFocusRef}
        className="flex fixed inset-0 z-50 justify-center items-center"
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/50" aria-hidden="true" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="relative w-full max-w-lg bg-white rounded-lg shadow-lg">
            {/* Header with Close Button */}
            <div className="flex justify-between items-center py-4 px-6 border-b border-gray-200">
              <h2 className="text-lg font-semibold text-gray-800">
                {header || "Modal Title"}
              </h2>
              <button
                onClick={onClose}
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
                aria-label="Close modal"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            {/* Modal Content */}
            <div className="overflow-y-auto p-6 max-h-[80vh]">{children}</div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};
