export const getCurrencySign = (currency: "EUR" | "SEK") => {
  switch (currency) {
    case "EUR":
      return "€";
    case "SEK":
      return "kr";
    default:
      throw new Error(`Unsupported currency: ${currency}`);
  }
};

export function getSpread(
  sellPrice: string | null,
  buyPrice: string | null,
  returnNumerical: boolean = false
): number | string {
  if (!sellPrice || !buyPrice) {
    return returnNumerical ? 0 : "-";
  }

  const sell = parseFloat(sellPrice);
  const buy = parseFloat(buyPrice);

  if (isNaN(sell) || isNaN(buy)) {
    return returnNumerical ? 0 : "-";
  }

  const spread = ((buy - sell) / buy) * 100;
  return returnNumerical ? spread : spread.toFixed(2);
}
