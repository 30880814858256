import {
  ComponentProps,
  Fragment,
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  FC,
  Ref,
  ReactNode,
} from "react";
import { Tab } from "@headlessui/react";
import classNames from "classnames";
import { PageLayout } from "../../PageLayout/PageLayout";
import {
  PagesCarousel,
  PagesCarouselProps,
} from "../PagesCarousel/PagesCarousel";

type NavTabType = typeof Tab & {
  CarouselPanels: (props: PagesCarouselProps) => JSX.Element;
  NavTab: ForwardRefExoticComponent<
    ComponentProps<typeof Tab> & RefAttributes<HTMLElement>
  >;
  Group: typeof Tab.Group;
  List: typeof Tab.List;
  Panels: typeof Tab.Panels;
  Panel: typeof Tab.Panel;
};

const NavTab = (
  props: ComponentProps<FC>,
  ref: Ref<HTMLElement> | undefined
) => (
  <div className="px-8">
    <Tab
      className={({ selected }) =>
        classNames(
          "py-2 whitespace-nowrap text-base border-b-4 transition-colors",
          {
            "border-kvarn-light-green text-kvarn-dark-green font-semibold":
              selected,
            "border-transparent hover:border-gray-200": !selected,
          }
        )
      }
      ref={ref}
      {...props}
    />
  </div>
);

NavTab.displayName = "NavTab";
NavTab.NavTab = forwardRef(NavTab) as NavTabType["NavTab"];
NavTab.NavTab.displayName = "NavTab.NavTab";

NavTab.Group = Tab.Group;

type NavTabListType = typeof Tab.List;
const NavTabList: NavTabListType = (props) => (
  <nav className="overflow-x-auto lg:overflow-x-visible p-2 w-full bg-gray-50 scroll-hidden">
    <div className="container px-4 mx-auto">
      <Tab.List
        className="flex flex-nowrap gap-4 items-end bg-gradient-to-t from-blue-50 to-white rounded-full shadow-md min-w-fit scroll-hidden"
        {...props}
      />
    </div>
  </nav>
);
NavTabList.displayName = "NavTabList";
NavTab.List = NavTabList;

const NavTabPanels = (props: PagesCarouselProps) => (
  <PagesCarousel {...props}>{props.children}</PagesCarousel>
);

NavTab.CarouselPanels = NavTabPanels;

NavTab.Panels = Tab.Panels;

type NavTabPanelType = typeof Tab.Panel;
const NavTabPanel: NavTabPanelType = ({ children, ...props }) => (
  <PageLayout>
    <Fragment>{children as ReactNode}</Fragment>
  </PageLayout>
);
NavTabPanel.displayName = "NavTabPanel";
NavTab.Panel = NavTabPanel;

export { NavTab };
