import { ReactNode } from "react";
import {
  Bounce,
  toast,
  ToastContainer,
  ToastContainerProps,
} from "react-toastify";

interface ToastProps extends ToastContainerProps {
  children?: ReactNode;
}

export const Toast = (props: ToastProps) => (
  <ToastContainer
    position={toast.POSITION.BOTTOM_CENTER}
    hideProgressBar={false}
    theme="colored"
    transition={Bounce}
    autoClose={5000}
    draggablePercent={60}
    className="z-[999999]"
    {...props}
  />
);
