import { ReactNode } from "react";
import classNames from "classnames";

interface CardProps {
  children: ReactNode;
  header?: ReactNode;
  onClick?: () => void;
  testId?: string;
  className?: string;
  headerClassName?: string;
}

export const Card = ({
  children,
  header,
  onClick,
  testId,
  className,
  headerClassName,
}: CardProps) => (
  <div
    {...(testId ? { "data-testid": testId } : {})}
    className={classNames(
      "flex overflow-hidden flex-col w-full h-full bg-white rounded-2xl border shadow-md",
      {
        "cursor-pointer": !!onClick,
      },
      className
    )}
    onClick={onClick}
  >
    {header && (
      <div
        className={classNames(
          "p-2 md:px-4 text-2xl font-bold bg-[#e6eeef]",
          headerClassName
        )}
      >
        {header}
      </div>
    )}
    {children}
  </div>
);
