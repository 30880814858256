import { MutableRefObject } from "react";
import { useGetPortfolioFeeDetails } from "api/holdings/useGetPortfolioFeeDetails";
import { useGetContactInfo } from "api/initial/useGetContactInfo";
import { CardWithEditableFields } from "components/Card/CardWithEditableFields";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { useGetContactIdData } from "providers/ContactIdProvider";
import { getCurrencySign } from "utils/currency";

interface DepositModalProps {
  modalInitialFocusRef: MutableRefObject<null>;
  onClose: () => void;
}

interface XS2AOptions {
  onFinished: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError: (error: any) => void;
  onLoad: () => void;
  onClose: () => void;
  autoClose: boolean;
}

declare global {
  interface Window {
    XS2A: {
      startFlow: (clientToken: string, options: XS2AOptions) => void;
    };
    onXS2AReady: (response: ImportDepositQueryResponse) => void;
  }
}

interface ImportDepositQueryResponse {
  clientToken: string;
  extId: string;
  sessionId: string;
}

export const UserProfile = ({
  onClose,
  modalInitialFocusRef,
}: DepositModalProps) => {
  const { t } = useModifiedTranslation();
  const { selectedContactId } = useGetContactIdData();
  const {
    data: { portfolios } = {
      portfolios: [],
    },
  } = useGetContactInfo(false, selectedContactId);

  const { data: cachedContactData } = useGetContactInfo();
  const currencyCode = cachedContactData?.portfoliosCurrency ?? "";

  const contactData = useGetContactInfo(false, selectedContactId);

  const portfolioFees = useGetPortfolioFeeDetails(
    portfolios?.[0]?.id.toString()
  );

  return (
    <div className="grid gap-2 min-w-[min(84vw,_375px)]">
      <CardWithEditableFields
        data={[
          {
            label: t("profile.name"),
            value: contactData.data?.name || "",
            fieldType: "string",
            isEditable: false,
          },
          {
            label: t("profile.email"),
            value: contactData?.data?.addresses?.[0]?.email || "",
            fieldType: "string",
            isEditable: false,
          },
          {
            label: t("profile.accountNumber"),
            value: portfolios?.[0]?.accounts?.[0].number || 0,
            fieldType: "string",
            isEditable: false,
            addCopyButton: true,
          },
        ]}
        cardHeader={t("profile.contactInfo")}
      />
      <CardWithEditableFields
        className="mt-4"
        data={[
          {
            label: t("profile.address"),
            value: contactData?.data?.addresses?.[0].address1 || "",
            fieldType: "string",
            isEditable: false,
          },
          {
            label: t("profile.zipCode"),
            value: contactData?.data?.addresses?.[0]?.zipCode || "",
            fieldType: "string",
            isEditable: false,
          },
          {
            label: t("profile.city"),
            value: contactData?.data?.addresses?.[0]?.city || "",
            fieldType: "string",
            isEditable: false,
          },
          {
            label: t("profile.phone"),
            value: contactData?.data?.addresses?.[0]?.phone1 || "",
            fieldType: "string",
            isEditable: false,
          },
          {
            label: t("profile.feeLevel"),
            value: portfolioFees?.data.feeLevelTag || "",
            fieldType: "string",
            isEditable: false,
          },
          {
            label: t("profile.feePercentage"),
            value: String((portfolioFees?.data.feePercentage || 0) * 100),
            fieldType: "string",
            isEditable: false,
          },

          {
            label: `${t("profile.cumulativeTradeAmount")} (${getCurrencySign(
              currencyCode as "EUR" | "SEK"
            )})`,
            value:
              portfolioFees?.data.cumulativeTradeAmount?.toLocaleString(
                undefined,
                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
              ) || 0,
            fieldType: "string",
            isEditable: false,
          },
        ]}
        cardHeader={t("profile.accountInfo")}
      />
    </div>
  );
};
