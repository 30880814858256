export const round = (number: number, decimalPlaces: number) =>
  Math.round((number + Number.EPSILON) * Math.pow(10, decimalPlaces)) /
  Math.pow(10, decimalPlaces);

export function formatLargeValue(value: number, decimals: number = 1): string {
  if (value < 1e6) {
    return value.toLocaleString();
  }

  const suffixes = [
    { threshold: 1e12, suffix: "T" }, // Trillion
    { threshold: 1e9, suffix: "B" }, // Billion
    { threshold: 1e6, suffix: "M" }, // Million
  ];

  for (const { threshold, suffix } of suffixes) {
    if (value >= threshold) {
      const formattedValue = (value / threshold).toFixed(decimals);
      return `${formattedValue}${suffix}`;
    }
  }

  return value.toString(); // Fallback
}
