import React from "react";
import { ReactComponent as CopyIcon } from "assets/copy-document.svg";
import classNames from "classnames";
import { Divider } from "components/Divider";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { IoPencilOutline } from "react-icons/io5";
import { toast } from "react-toastify";

interface Field {
  label: string;
  value: string | number | JSX.Element;
  fieldType: "string" | "number";
  isEditable: boolean;
  addCopyButton?: boolean;
}

type CardProps = {
  data: Field[];
  cardHeader: string;
  className?: string;
};

export const CardWithEditableFields: React.FC<CardProps> = ({
  data,
  cardHeader,
  className,
}) => {
  const { t } = useModifiedTranslation();
  const size = data.length;

  return (
    <div className={classNames("p-1 bg-white rounded-lg shadow-md", className)}>
      <div className="p-4 rounded-lg bg-[#e6eeef]">
        <h2 className="text-lg font-semibold text-gray-700">{cardHeader}</h2>
        <div className="mt-4 bg-white rounded-lg">
          {data.map((field, idx) => {
            const isLast = idx === size - 1;
            return (
              <React.Fragment key={field.label}>
                <div className="flex justify-between items-center py-2 px-4 bg-white rounded-lg min-h-[48px]">
                  <span className="text-sm text-gray-500 capitalize">
                    {field.label}
                  </span>
                  <div className="flex gap-3 items-center">
                    <span className="text-sm font-medium text-gray-900">
                      {field.value}
                    </span>
                    {field.addCopyButton && (
                      <button
                        onClick={async () => {
                          try {
                            await navigator.clipboard.writeText(
                              field.value as string
                            );
                            toast.success(t("transferList.copySuccess"), {
                              autoClose: 3000,
                            });
                          } catch (err) {
                            console.error("Failed to copy text: ", err);
                          }
                        }}
                        className="flex items-center text-gray-400 hover:text-gray-600"
                      >
                        <CopyIcon className="w-5 h-5 transition-transform active:scale-90" />
                      </button>
                    )}
                    {field.isEditable && (
                      <button className="flex items-center text-gray-400 hover:text-gray-600">
                        <IoPencilOutline className="w-5 h-5" />
                      </button>
                    )}
                  </div>
                </div>
                {!isLast && (
                  <div className="px-2">
                    <Divider className="my-1" />
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};
